 
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@layer base {
  body {
    margin: 0; /* Removes browser default margin */
    padding: 0; /* Removes unnecessary padding */
    box-sizing: border-box; /* Ensures width calculations include padding/border */
    overflow-x: hidden; /* Prevents horizontal overflow */
    scroll-behavior: smooth; /* Optional for smoother scrolling */
    font-family: 'Lexend', sans-serif !important; 
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit; /* Consistent box-sizing across all elements */
  }

  @media (max-width: 768px) {
    body {
      overflow-x: hidden; /* Additional safeguard for mobile/tablets */
    }
  }
}

